import axios from 'axios';

export type DisclosureGradeRequest = {
  questionnaireHref: string;
  items: DisclosureGradeRequestItem[];
};

export type DisclosureGradeRequestItem = {
  guid: string;
  answers: string[];
};

export type GradeResponse = {
  grade: number;
  description: string;
};

export const fetchDisclosureGrade = async (request: DisclosureGradeRequest) => {
  const response = await axios.request<GradeResponse>({
    method: 'post',
    url: '/disclosureGrade',
    data: request
  });
  return response.data;
};
