/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { fetchS3SignedUrl } from '../api/fetchS3SignedUrl';
import { uploadFileToS3 } from '../api/uploadFileToS3';
import { validateSpreadsheetFile } from '../api/validateSpreadsheetFile';
import { FormTypeSelector } from './FormTypeSelector';

export type UploadQuestionnaireProps = {
  setForm: (file: File | undefined, json: JSON | undefined) => void;
  setFormType: (formType?: string) => void;
  formTypeOptions: string[];
  formType: string | undefined;
  currentSpreadsheetFile: File | undefined;
};

export const onDropCallback = async (
  acceptedFiles: File[],
  setForm: (file: File | undefined, json: JSON | undefined) => void,
  formType?: string
) => {
  try {
    const url = await fetchS3SignedUrl();
    console.log('Uploading File : ', acceptedFiles[0]);
    await uploadFileToS3(url, acceptedFiles[0]);
    const validationResponse =
      formType !== undefined
        ? await validateSpreadsheetFile(formType)
        : { valid: false, validationErrors: ['No Form type specified', `formType is ${formType}`] };
    if (validationResponse.valid === true && validationResponse.formJson) {
      const completeFormJson = {
        ...validationResponse.formJson,
        href: 'TBD'
      };
      setForm(acceptedFiles[0], completeFormJson);
    } else if (validationResponse.valid === false && validationResponse.validationErrors.length > 0) {
      console.error('Form Validation Error', validationResponse);
      alert(`Form validation error(s) : \n * ${validationResponse.validationErrors.join('\n * ')}`);
      setForm(undefined, undefined);
    } else {
      console.error('Error with response from Forms API validation endpoint', validationResponse);
      alert('Error loading form from upload (see console for details');
      setForm(undefined, undefined);
    }
  } catch (e: any) {
    console.error('Error loading form from upload', e);
    alert(`Error loading form from upload (see console for details):\n${e.message}`);
    setForm(undefined, undefined);
  }
};

export const UploadQuestionnaire = (props: UploadQuestionnaireProps) => {
  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState(props.formType);

  useEffect(() => {
    props.setFormType(formType);
  }, [formType]);

  const onDrop = async (acceptedFiles: File[]) => {
    setLoading(true);
    await onDropCallback(acceptedFiles, props.setForm, formType);
    setLoading(false);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onDrop
  });

  return (
    <>
      <FormTypeSelector
        formTypeOptions={props.formTypeOptions}
        setFormType={(form: string | undefined) => {
          setFormType(form);
          props.setForm(undefined, undefined);
        }}
        formType={formType}
      />

      {formType && !props.currentSpreadsheetFile && (
        <>
          <h4>{`File Upload for Validating forms/${formType} `}</h4>
          <StyledDropzone {...getRootProps()}>
            <input {...getInputProps()} />
            {loading ? (
              <p>Uploading and validating spreadsheet. Please wait...</p>
            ) : (
              <p>Drag and drop a file here, or click to select a file</p>
            )}
          </StyledDropzone>
        </>
      )}
      <aside>
        {props.currentSpreadsheetFile && (
          <>
            <h4 data-test-id="file-header">File</h4>
            <ul>
              <li data-test-id="file-info" key={props.currentSpreadsheetFile.name}>
                {props.currentSpreadsheetFile.name} - {props.currentSpreadsheetFile.size} bytes{' '}
                <button
                  data-test-id="remove-button"
                  onClick={() => {
                    props.setForm(undefined, undefined);
                  }}
                >
                  Remove
                </button>
              </li>
            </ul>
          </>
        )}
      </aside>
    </>
  );
};

export const StyledDropzone = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: black;
  outline: none;
  transition: border 0.24s ease-in-out;
`;
