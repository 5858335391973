import axios from 'axios';

export const uploadFileToS3 = async (url: string, file: File) => {
  const uninterceptedAxiosInstance = axios.create();
  const response = await uninterceptedAxiosInstance.put(url, file, {
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  });
  return response.data;
};
