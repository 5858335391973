import { AppState, QuestionState } from './AppState';
import { EnvironmentName } from './EnvironmentName';
import { ThemeColors } from '../styles/theme';
import { Questionaire } from './QEQuestion';
import { Announcement } from './Announcement';

export const CLIENT_ANNOUNCER_SOURCES = ['inspector', 'seller'] as const;
export type ClientAnnouncerSource = typeof CLIENT_ANNOUNCER_SOURCES[number];

export type AppConfiguration = {
  clientApplicationName: string;
  debug?: boolean;
  username?: string;
  environment: EnvironmentName;
  rootElementId: string;
  onStateChange: (appState: AppState) => void;
  onLinkClick?: (url: string) => void;
  authToken: string; // AuthTkt, Mashery or Auth0 Bearer Token
  initialState: Pick<AppState, 'questionResponses' | 'announcements'>;
  announcerSource: ClientAnnouncerSource;
  questionnaireHref?: string;
  readonly?: boolean;
  colors?: Partial<ThemeColors>;
  unitHref?: string;
  debugQuestionnaire?: Questionaire;
  debugDictionary?: Announcement[];
  tags?: string[];
  userType?: string[];
  questionMessages?: QuestionMessage[];
  inlineImaging?: boolean;
  useDDS?: boolean;
};

export type QuestionMessage = { tags: string[]; message: string };

export type LegacyQuestionState = {
  guid: string;
  values: string[];
  source: string;
};

export type BackwardCompatibleAppConfiguration = Omit<AppConfiguration, 'initialState'> & {
  initialState: Pick<AppState, 'announcements'> & {
    questionResponses: (LegacyQuestionState | QuestionState)[];
  };
};

export const VEHICLE_DECODE_SOURCES: Record<string, Record<string, string>> = {
  useVDS: { id: 'useVDS', description: '[VDS] Vehicle Description Service' },
  useDDS: { id: 'useDDS', description: '[DDS] Vehicle Designated Description' }
};
