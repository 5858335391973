import axios from 'axios';

export const validateSpreadsheetFile = async (formType: string) => {
  const response = await axios.get(`/forms/validateSpreadsheet/${formType}/file`).catch(e => {
    if (e.response && e.response.status === 400 && e.response.data.errors.length > 0) {
      return {
        data: { valid: false, validationErrors: (e.response.data.errors as Error[]).map(error => error.message) }
      };
    }
    throw e;
  });
  return response.data;
};
