import React from 'react';

export const FormTypeSelector = (props: {
  formTypeOptions: string[];
  setFormType: (formType?: string) => void;
  formType: string | undefined;
}) => {
  return (
    <>
      <h4>Select a form type for validation: </h4>
      <select
        data-test-id="form-type-select"
        value={props.formType || ''}
        onChange={event => {
          props.setFormType(event.target.value || undefined);
        }}
      >
        <option key="none" value={undefined}></option>
        {props.formTypeOptions.map((form, index) => (
          <option key={index} value={form}>
            {form}
          </option>
        ))}
      </select>
    </>
  );
};
